import * as Yup from 'yup';

import { validatePhone } from 'utils/functions';

export const yupSelectInput = (message?: string) => {
  // eslint-disable-next-line
  const msg = message ? message : '${path} is a required field';
  return (
    Yup.array()
      .length(1, msg)
      // eslint-disable-next-line
      .of(Yup.object().required('a valid ${path} option required!'))
  );
};

export const yupFilterInput = Yup.array()
  // eslint-disable-next-line
  .length(1, '${path} is a required field')
  // eslint-disable-next-line
  .of(Yup.object().required('a valid ${path} option required!'));

export const yupPhoneInput = Yup.string()
  .trim()
  .transform((v, o) => (o === '' ? null : v))
  .test('phone_number', 'enter a valid phone number starting with country code.', (value, context) => {
    let isRequired = false;
    const schema = context.schema;
    if ('exclusiveTests' in schema && 'required' in schema.exclusiveTests) {
      if (schema.exclusiveTests.required) {
        isRequired = true;
      }
    }
    return (isRequired && value) || (value && value.length > 0) ? validatePhone(value) : true;
  });

export const authValidationSchema = Yup.object().shape({
  email: Yup.string().email('This is not a valid email').required('Please enter your email address.'),
  password: Yup.string().required('Please enter your password.'),
});
