import { Fragment } from 'react';
import { Badge, Card, Container } from 'react-bootstrap';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { BackButton } from 'components/back-button';

import { IErrorResponse } from 'interfaces/IToast';

declare type ErrorBoundaryType = IErrorResponse | string;
const ErrorBoundary = () => {
  const error = useRouteError();

  return (
    <Container className="my-5">
      <Card className="shadow">
        <Card.Body>
          {isRouteErrorResponse(error) ? (
            <Fragment>
              <ErrorBody
                error={{
                  code: error.status,
                  response: error.statusText,
                  message: error.data as string,
                }}
              />
            </Fragment>
          ) : (
            <ErrorBody error={error as ErrorBoundaryType} />
          )}
          <BackButton />
        </Card.Body>
      </Card>
    </Container>
  );
};

const ErrorBody = ({ error }: { error: ErrorBoundaryType }) => {
  if (typeof error === 'string') {
    const message = error.toLowerCase().includes('chunk')
      ? 'Oops! Something went wrong. Please check your internet connection. If the problem persists, contact support.'
      : (error ?? 'Error: Something went wrong!');
    return <Card.Title className="fw-bold text-black">{message}</Card.Title>;
  }

  return (
    <Fragment>
      <Card.Title className="fw-bold text-black">
        {error.response ?? 'Error!'}
        {error.code && (
          <Badge pill bg="grayish" className="text-primary text-opacity-50 small fw-normal mx-2 py-2 px-3">
            Error code {error.code}
          </Badge>
        )}
      </Card.Title>
      <Card.Text>
        {error.message.toLowerCase().includes('chunk')
          ? 'Oops! There was an issue loading this page. Please check your internet connection. If the problem persists, contact support.'
          : error.message}
      </Card.Text>
    </Fragment>
  );
};

export default ErrorBoundary;
