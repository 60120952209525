import { Stack } from 'react-bootstrap';

import ItemPrice from './item-price';

interface IProps {
  value?: { from: string | number; to: string | number };
}

const ItemRange = ({ value }: IProps) => {
  if (!value || !value.from || !value.to) return <span>-</span>;

  return (
    <Stack direction="horizontal" gap={1}>
      <ItemPrice value={value.from} />
      <div> - </div>
      <ItemPrice value={value.to} />
    </Stack>
  );
};

export default ItemRange;
