import { useEffect } from 'react';
import { Provider } from 'react-redux';

import { store } from 'store/redux';

import { SuspenseHOC } from 'core-ui/suspense/suspense-hoc';
import { SwalExtended } from 'core-ui/sweet-alert';

import AuthProvider from 'context/auth-context';

import { RouterWrapper } from 'routes/route-wrapper';

const App = () => {
  useEffect(() => {
    const handlePopState = () => {
      if (SwalExtended.isVisible()) {
        SwalExtended.close();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <Provider store={store}>
      <AuthProvider>
        <RouterWrapper />
      </AuthProvider>
    </Provider>
  );
};

export default SuspenseHOC(App);
