import { useCallback } from 'react';

import { Confirmation } from 'components/alerts';
import { LayoutProvider } from 'components/layouts/context/layout-context';
import { AuthHeader } from 'components/layouts/header';
import OutletSuspense from 'components/layouts/layout-container/outlet-suspense';
import { Sidebar } from 'components/layouts/sidebar';

import { Notify } from 'core-ui/toast';

import { useAuthState } from 'hooks/useAuthState';

import { AdminNavbar } from '../admin-navbar';

export interface IAdminLayout {
  noSidebar?: boolean;
}

const AdminLayout = ({ noSidebar = false }: IAdminLayout) => {
  const { handleLogout } = useAuthState();
  const confirmLogout = useCallback(() => {
    Confirmation({
      type: 'warning',
      title: 'Logout?',
      description: 'You are about to logout.\nAre you sure this is what you want?',
    }).then(result => {
      if (result.isConfirmed) {
        handleLogout();
        Notify.show({ title: 'Logout Successfully', type: 'success' });
      }
    });
  }, [handleLogout]);

  return (
    <LayoutProvider>
      <AuthHeader responsiveMenu={!noSidebar} onLogout={confirmLogout} />
      <Sidebar noSidebar={noSidebar} NavAction={!noSidebar ? AdminNavbar : undefined}>
        <OutletSuspense />
      </Sidebar>
    </LayoutProvider>
  );
};

export default AdminLayout;

export const TenantLayout = () => {
  const { handleLogout } = useAuthState();
  const confirmLogout = useCallback(() => {
    Confirmation({
      type: 'warning',
      title: 'Logout?',
      description: 'You are about to logout.\nAre you sure this is what you want?',
    }).then(result => {
      if (result.isConfirmed) {
        handleLogout();
        Notify.show({ title: 'Logout Successfully', type: 'success' });
      }
    });
  }, [handleLogout]);

  return (
    <LayoutProvider>
      <AuthHeader settingsButton={false} onLogout={confirmLogout} />
      <Sidebar>
        <OutletSuspense />
      </Sidebar>
    </LayoutProvider>
  );
};
